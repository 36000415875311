import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useCallback } from "react";

// Custom hook for window size
function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

// // Integrated Sort component
// function Sort({ onSortChange, sortKey, isOpen, toggle }) {
//   console.log("Is Open:", isOpen);
//   return (
//     <div className="w-full flex items-center">
//       <select
//         value={sortKey}
//         onMouseDown={(e) => toggle()} // Toggle on mouse down
//         onChange={(e) => onSortChange(e.target.value)}
//         className="w-full"
//       >
//         <option value="">Default</option>
//         <option
//           value="price_asc"
//           style={{ display: isOpen ? "block" : "none" }}
//         >
//           Price (Low to High)
//         </option>
//         <option
//           value="price_desc"
//           style={{ display: isOpen ? "block" : "none" }}
//         >
//           Price (High to Low)
//         </option>
//         <option value="ref_asc" style={{ display: isOpen ? "block" : "none" }}>
//           Ref. Number (Ascending)
//         </option>
//       </select>
//     </div>
//   );
// }
function SortPanel({
  title,
  options,
  selectedOption,
  onChange,
  isOpen,
  toggle,
}) {
  return (
    <div>
      {/* <button
        onClick={toggle}
        className="w-full font-bold mb-2 text-left text-black"
      >
        {title}
      </button> */}
      {isOpen && (
        <div>
          <div className="w-full mb-2 font-bold text-left text-black">
            {title}
          </div>
          {options.map((option) => (
            <label key={option} className="block text-black text-left">
              <input
                type="radio"
                name="sorting"
                value={option.value}
                checked={selectedOption === option.value}
                onChange={(e) => onChange(e.target.value)}
              />
              {option.label}
            </label>
          ))}
        </div>
      )}
    </div>
  );
}

function FilterPanel({
  title,
  options,
  selectedOptions,
  onChange,
  isOpen,
  toggle,
}) {
  return (
    <div className="">
      {/* <button
        onClick={toggle}
        className="w-full mb-2 font-bold text-left text-black"
      >
        
      </button> */}
      {isOpen && (
        <div>
          <div className="w-full mb-2 md:mx-4 font-bold text-left text-black">
            {title}
          </div>

          {options.map((option) => (
            <label
              key={option}
              className="block text-black mb-2  md:mx-4 text-left"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #ccc", // Adds an underline across the full width of the label
                paddingBottom: "8px", // Adds some space between the content and the underline
              }}
            >
              <span>{option}</span>
              <input
                type="checkbox"
                value={option}
                checked={selectedOptions.includes(option)}
                onChange={(e) => onChange(e.target.checked, option)}
                style={{
                  width: "16px", // Square dimensions
                  height: "16px",
                  appearance: "none", // Removes default system styling
                  backgroundColor: selectedOptions.includes(option)
                    ? "black"
                    : "white", // Background color changes when checked
                  borderColor: "black", // Border color
                  borderWidth: "1px",
                  cursor: "pointer", // Pointer cursor on hover
                }}
              />
            </label>
          ))}
        </div>
      )}
    </div>
  );
}

function Filter({ filterOptions, products, onFilterResults }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortKey, setSortKey] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showFilter, setShowFilter] = useState(true);
  const lastScrollY = useRef(0);
  const [isOpen, setIsOpen] = useState(false);
  const [width] = useWindowSize(); // Get the width from the custom hook
  const isDesktop = width >= 720; // Consider desktop if width is 1024px or more
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [originOptions, setOriginOptions] = useState([]);
  const [movementOptions, setMovementOptions] = useState([]);
  const [priceRangesOptions, setPriceRangesOptions] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState({
    priceRanges: [],
    brands: [],
    gender: [],
    origin: [],
    movement: [],
  });

  useEffect(() => {
    setBrandsOptions(filterOptions.brands || []);
  }, [filterOptions.brands]);
  useEffect(() => {
    setGenderOptions(filterOptions.gender || []);
  }, [filterOptions.gender]);
  useEffect(() => {
    setOriginOptions(filterOptions.origin || []);
  }, [filterOptions.origin]);
  useEffect(() => {
    setPriceRangesOptions(filterOptions.priceRanges || []);
  }, [filterOptions.priceRanges]);
  useEffect(() => {
    setMovementOptions(filterOptions.movement || []);
  }, [filterOptions.movement]);

  // Sorting options example
  const sortOptions = [
    { value: "price_asc", label: "Price (Low to High)" },
    { value: "price_desc", label: "Price (High to Low)" },
    { value: "ref_asc", label: "Ref. Number (A-Z)" },
  ];

  // Custom hook for handling filter visibility based on scroll position
  useEffect(() => {
    if (!true) return; // Apply scroll logic only on desktop or both

    const controlFilterVisibility = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY.current && currentScrollY > 100) {
        setShowFilter(false);
      } else if (currentScrollY < lastScrollY.current) {
        setShowFilter(true);
      }
      lastScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", controlFilterVisibility);
    controlFilterVisibility(); // Call on mount to set initial state
    return () => window.removeEventListener("scroll", controlFilterVisibility);
  }, [true]); // Only re-run the effect if isDesktop changes

  useEffect(() => {
    const applySelectedFilters = () => {
      return products.filter((product) => {
        // Handling price ranges
        const priceRangesMatch =
          selectedFilters.priceRanges.length === 0 ||
          selectedFilters.priceRanges.some((range) => {
            let minPrice = 0;
            let maxPrice = Infinity;

            if (range.includes("+")) {
              minPrice = parseInt(range.replace(/[^\d]/g, ""), 10); // Handle open-ended ranges like "+ 800,000 IQD"
            } else {
              const rangeParts = range.split(" - ");
              if (rangeParts.length === 2) {
                minPrice = parseInt(rangeParts[0].replace(/[^\d]/g, ""), 10); // Extract the minimum price
                maxPrice = parseInt(rangeParts[1].replace(/[^\d]/g, ""), 10); // Extract the maximum price
              }
            }
            // Safely handle product.PRICE whether it's a number or a string
            const productPrice =
              typeof product.PRICE === "number"
                ? product.PRICE
                : parseInt(product.PRICE.replace(/[^0-9]/g, ""), 10);
            return productPrice >= minPrice && productPrice <= maxPrice;
          });

        // Helper function to find movement in the product's info fields
        const matchesMovement = (product, selectedMovements) => {
          if (selectedMovements.length === 0) return true; // No filter set for movement
          for (let i = 1; i <= 40; i++) {
            const infoKey = `Info${i}`;
            const valueKey = `Value${i}`;
            if (
              product[infoKey] === "Movement" &&
              selectedMovements.includes(product[valueKey])
            ) {
              return true;
            }
          }
          return false;
        };

        const brandMatch =
          selectedFilters.brands.length === 0 ||
          selectedFilters.brands.includes(product.BRAND);
        const genderMatch =
          selectedFilters.gender.length === 0 ||
          selectedFilters.gender.includes(product.GENDER);
        const originMatch =
          selectedFilters.origin.length === 0 ||
          selectedFilters.origin.includes(product.ORIGIN);

        const movementMatch = matchesMovement(
          product,
          selectedFilters.movement
        );

        return (
          priceRangesMatch &&
          brandMatch &&
          genderMatch &&
          originMatch &&
          movementMatch
        );
      });
    };

    const newFilteredProducts = applySelectedFilters();

    if (
      JSON.stringify(newFilteredProducts) !== JSON.stringify(filteredProducts)
    ) {
      setFilteredProducts(newFilteredProducts);
      onFilterResults(newFilteredProducts);
    }
  }, [products, selectedFilters]);

  // Sync filters from URL
  useEffect(() => {
    const params = Object.fromEntries([...searchParams]);
    const newFilters = {
      priceRanges: params.priceRanges ? params.priceRanges.split(",") : [],
      brands: params.brands ? params.brands.split(",") : [],
      gender: params.gender ? params.gender.split(",") : [],
      origin: params.origin ? params.origin.split(",") : [],
      movement: params.movement ? params.movement.split(",") : [],
    };

    // Only update state if there is actually a change to avoid infinite loops
    if (JSON.stringify(newFilters) !== JSON.stringify(selectedFilters)) {
      setSelectedFilters(newFilters);
    }
  }, [searchParams]); //

  const updateURLParams = useCallback(
    (filters) => {
      const newSearchParams = new URLSearchParams();
      Object.entries(filters).forEach(([key, value]) => {
        if (value.length > 0) {
          // Remove commas before joining and setting the URL search params
          const cleanedValues = value.map((v) => v.replace(/,/g, ""));
          newSearchParams.set(key, cleanedValues.join(","));
        }
      });
      setSearchParams(newSearchParams, { replace: true });
    },
    [setSearchParams]
  );

  const togglePanels = () => {
    setIsOpen(!isOpen); // Toggle all panels
  };

  const handleFilterChange = (filterType) => (isChecked, value) => {
    const updatedSelectedFilters = {
      ...selectedFilters,
      [filterType]: isChecked
        ? [...selectedFilters[filterType], value]
        : selectedFilters[filterType].filter((item) => item !== value),
    };
    setSelectedFilters(updatedSelectedFilters);
    updateURLParams(updatedSelectedFilters);
  };

  useEffect(() => {
    if (isOpen && isDesktop) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }

    // Clean up function to ensure scrolling is re-enabled when component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]); // Effect runs only when isFilterOpen changes

  return (
    <div className="fixed w-full mt-0  md:top-18">
      {isDesktop ? (
        //////////////////////////////// Desktop View  ${ showFilter ? "translate-y-0" : "-translate-y-full"}
        <div
          className={`border-b bg-white backdrop-blur bg-opacity-70  w-full transition-transform duration-300 ease-in-out 
          ${
            showFilter ? "translate-y-0" : "-translate-y-16 md:-translate-y-20"
          } 
          ${isOpen ? "h-screen" : ""}
          z-50 px-0  md:px-4 `}
        >
          <div
          //  className={`fixed inset-0 bg-white bg-opacity-70
          //  backdrop-blur transition-transform duration-300
          //  ease-in-out z-50
          //   ${isFilterOpen ? "translate-y-0" : "-translate-y-full"}`}
          >
            <div
              className={`
          flex w-full    content-center place-items-center justify-center`}
             
            >
              <button
                onClick={togglePanels}
                className="flex w-full filter-toggle content-center text-2xl items-center ml-6 md:ml-14"
              >
                <img
                  src="/Icons and Logos/settings-sliders.svg"
                  alt="Settings Icon"
                  className="filter-icon content-center mr-5 h-5 w-5"
                />
                Filters
              </button>
            </div>
            <div className="text-white grid w-full text-center items-top fixed overflow-y-scroll grid-cols-1 md:grid-cols-3 lg:grid-cols-5 p-1 md:gap-4 px-6 md:px-14"
             style={{
              maxHeight: "calc(100vh - 148px)", // Adjust the fixed height from the top
              bottom: "0px",
              top: showFilter ? "40px" : "0px",  // Adjusts the top based on whether filters are shown
              transition: 'top 0.3s', // Smooth transition for the 'top' property
            }}
            >
              {/* Your FilterPanels... */}
              {/* <SortPanel
                title="Sort By"
                options={sortOptions}
                selectedOption={sortKey}
                onChange={setSortKey}
                isOpen={isOpen}
                toggle={togglePanels}
              /> */}
              <FilterPanel
                title="Price Ranges"
                options={priceRangesOptions}
                selectedOptions={selectedFilters.priceRanges}
                onChange={handleFilterChange("priceRanges")}
                isOpen={isOpen}
                toggle={togglePanels}
              />
              <FilterPanel
                title="Gender"
                options={genderOptions}
                selectedOptions={selectedFilters.gender}
                onChange={handleFilterChange("gender")}
                isOpen={isOpen}
                toggle={togglePanels}
              />
              <FilterPanel
                title="Movement"
                options={movementOptions}
                selectedOptions={selectedFilters.movement}
                onChange={handleFilterChange("movement")}
                isOpen={isOpen}
                toggle={togglePanels}
              />

              <FilterPanel
                title="Brands"
                options={brandsOptions}
                selectedOptions={selectedFilters.brands}
                onChange={handleFilterChange("brands")}
                isOpen={isOpen}
                toggle={togglePanels}
              />

              <FilterPanel
                title="Origin"
                options={originOptions}
                selectedOptions={selectedFilters.origin}
                onChange={handleFilterChange("origin")}
                isOpen={isOpen}
                toggle={togglePanels}
              />
            </div>
          </div>
        </div>
      ) : (
        /////////////////////////////// Mobile View
        <div className="absolute z-50">
          <button
            className={`p-1.5 rounded-lg bg-black bg-opacity-70 backdrop-blur-lg fixed bottom-0 left-0 m-6 z-50 transform ${
              isOpen ? "-translate-x-1 w-6/12 h-9" : "translate-x-0 w-9 h-9"
            } transition-transform duration-1500 ease-in-out
            flex items-center justify-center 
            text-white font-bold text-lg
            `}
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? (
              <span className=" text-center">Close</span> // Display "Close" when the filter is open
            ) : (
              <img
                src={"/Icons and Logos/settings-sliders.svg"}
                alt="Open Filters"
                className="invert w-6 h-6"
              /> // Show the settings icon when the filter is closed
            )}
          </button>

          <div
            className={` fixed  transform  overflow-y-scroll
            ${isOpen ? "translate-x-0" : "-translate-x-full"}
            ${
              showFilter
                ? "translate-y-0 "
                : "-translate-y-0 md:-translate-y-20"
            }

            w-3/5   border-r transition-transform duration-300 ease-in-out bg-white backdrop-blur bg-opacity-5  z-45`}
            style={{
              maxHeight: showFilter ? "calc(100vh - 128px)" : "100vh", // Adjust maxHeight based on showFilter
              bottom: "0px", // Ensures the panel ends at the bottom of the viewport
            }}
          >
            <div className="text-white grid w-full text-center  grid-cols-1 md:grid-cols-3 lg:grid-cols-6   md:gap-4 px-6 md:px-14">
              {/* Your FilterPanels... */}
              {/* <SortPanel
                title="Sort By"
                options={sortOptions}
                selectedOption={sortKey}
                onChange={setSortKey}
                isOpen={true}
                toggle={togglePanels}
              /> */}
              <FilterPanel
                title="Price Ranges"
                options={priceRangesOptions}
                selectedOptions={selectedFilters.priceRanges}
                onChange={handleFilterChange("priceRanges")}
                isOpen={true}
                toggle={togglePanels}
              />
              <FilterPanel
                title="Gender"
                options={genderOptions}
                selectedOptions={selectedFilters.gender}
                onChange={handleFilterChange("gender")}
                isOpen={true}
                toggle={togglePanels}
              />
              <FilterPanel
                title="Movement"
                options={movementOptions}
                selectedOptions={selectedFilters.movement}
                onChange={handleFilterChange("movement")}
                isOpen={true}
                toggle={togglePanels}
              />

              <FilterPanel
                title="Brands"
                options={brandsOptions}
                selectedOptions={selectedFilters.brands}
                onChange={handleFilterChange("brands")}
                isOpen={true}
                toggle={togglePanels}
              />

              <FilterPanel
                title="Origin"
                options={originOptions}
                selectedOptions={selectedFilters.origin}
                onChange={handleFilterChange("origin")}
                isOpen={true}
                toggle={togglePanels}
              />
              <div className="h-16"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Filter;
