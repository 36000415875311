import React, { useState, useEffect } from "react";
import Slider from 'react-slick';
import ProductCard from '../components/ProductCard';



function HomePage() {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  // Images for landing offers and collections
  const landingImages = [
    "/Icons and Logos/HomePageMaterials/Landing Images/2019LGC-1600x800-PR.jpg",
    "/Icons and Logos/HomePageMaterials/Landing Images/16.9-ratio.jpg",
    "/Icons and Logos/HomePageMaterials/Landing Images/casio-mens-outdoor-watch-prt-b50-main.jpg",
    "/Icons and Logos/HomePageMaterials/Landing Images/WBE5116_FC8266_1000.jpg_impolicy=th-pdp-day-night-day.jpg",
    "/Icons and Logos/HomePageMaterials/Landing Images/WBP201B.BA0632_USP1.jpg"
  ];

  const menCollectionImages = [
    "/Icons and Logos/HomePageMaterials/Collections/Men Images/5db8a2a58a2c946b0dea96fe_continut2.jpg",
    "/Icons and Logos/HomePageMaterials/Collections/Men Images/61a8df8e1b9d84811c4ea91e94720b38.jpg",
    "/Icons and Logos/HomePageMaterials/Collections/Men Images/437f9ec96e6d3b90b0b5a9c31415dd3b.jpg",
    // Add more images as needed
  ];

  const womenCollectionImages = [
    "/Icons and Logos/HomePageMaterials/Collections/Women Images/083ace924a5742a6b082095e217061d2.jpg",
    "/Icons and Logos/HomePageMaterials/Collections/Women Images/DBObisVW0AAI6JZ.jpg",
    "/Icons and Logos/HomePageMaterials/Collections/Women Images/ec6709e6277a499484643aa6b4fe8bdd.jpg",
    // Add more images as needed
  ];


  const brands = [
    { src: "/Icons and Logos/BrandsLogos/balmain.jpg", link: "https://www.watches-house.com/index.html#/store?brands=Balmain", name: "Balmain" },
    { src: "/Icons and Logos/BrandsLogos/calvinklein.jpg", link: "https://www.watches-house.com/index.html#/store?brands=Calvin+Klein", name: "Calvin Klein" },
    { src: "/Icons and Logos/BrandsLogos/Casio_logo.jpg", link: "https://www.watches-house.com/index.html#/store?brands=Casio", name: "Casio" },
    { src: "/Icons and Logos/BrandsLogos/Citizen_logo_black.jpg", link: "https://www.watches-house.com/index.html#/store?brands=Citizen", name: "Citizen" },
    { src: "/Icons and Logos/BrandsLogos/g-shock.svg", link: "https://www.watches-house.com/index.html#/store?brands=Casio+G-Shock", name: "G-Shock" },
    { src: "/Icons and Logos/BrandsLogos/JOVIAL_Official_Logo.jpg", link: "https://www.watches-house.com/index.html#/store?brands=Jovial", name: "Jovial" },
    //{ src: "/Icons and Logos/BrandsLogos/VOGUE logo.jpg", link: "#", name: "Vogue Logo" },
    { src: "/Icons and Logos/BrandsLogos/longines-logo.jpg", link: "https://www.watches-house.com/index.html#/store?brands=Longines", name: "Longines" },
    // { src: "/Icons and Logos/BrandsLogos/Q&Q1 .png.jpg", link: "https://www.watches-house.com/index.html#/store", name: "Q&Q" },
    { src: "/Icons and Logos/BrandsLogos/Seiko.jpg", link: "https://www.watches-house.com/index.html#/store?brands=Seiko", name: "Seiko" },
    { src: "/Icons and Logos/BrandsLogos/Swiss-Military-.jpg", link: "https://www.watches-house.com/index.html#/store?brands=Swiss+Military", name: "Swiss Military" },
    { src: "/Icons and Logos/BrandsLogos/tag-heuer-1.jpg", link: "https://www.watches-house.com/index.html#/store?brands=Tag+Heuer", name: "Tag Heuer" },
    { src: "/Icons and Logos/BrandsLogos/tissot square.jpg", link: "https://www.watches-house.com/index.html#/store?brands=Tissot", name: "Tissot" },
    { src: "/Icons and Logos/BrandsLogos/versus.jpg", link: "https://www.watches-house.com/index.html#/store?brands=Versus+Versace", name: "Versus" },
    { src: "/Icons and Logos/BrandsLogos/victorinox-swiss-army-logo-vector.jpg", link: "https://www.watches-house.com/index.html#/store?brands=Victorinox", name: "Victorinox" },
    { src: "/Icons and Logos/BrandsLogos/Aerowatch logo.svg", link: "https://www.watches-house.com/index.html#/store?brands=Aerowatch", name: "Aerowatch" },
    { src: "/Icons and Logos/BrandsLogos/tommy hilfiger Logo.svg", link: "https://www.watches-house.com/index.html#/store?brands=Tommy+Hilfiger", name: "Tommy Hilfiger" },
    { src: "/Icons and Logos/BrandsLogos/timex logo.png", link: "https://www.watches-house.com/index.html#/store?brands=Timex", name: "Timex" },
    { src: "/Icons and Logos/BrandsLogos/festina logo.svg", link: "https://www.watches-house.com/index.html#/store?brands=Festina", name: "Festina" },
    { src: "/Icons and Logos/BrandsLogos/boss logo.png", link: "https://www.watches-house.com/index.html#/store?brands=Hugo+Boss", name: "Hugo Boss" },
    { src: "/Icons and Logos/BrandsLogos/Mathey Tissot.png", link: "https://www.watches-house.com/index.html#/store?brands=Mathey-Tissot", name: "Mathey Tissot" },
    { src: "/Icons and Logos/BrandsLogos/Certina logo.svg", link: "https://www.watches-house.com/index.html#/store?brands=Certina", name: "Certina" }
  ];

  const renderBrandLogo = (brand, index) => (
    <a href={brand.link} key={index} className="flex justify-center items-center p-4 shadow-lg hover:shadow-xl transition-shadow duration-300">
      <img src={brand.src} alt={`${brand.name} Logo`} className="h-20 w-auto object-contain" />
    </a>
  );

  const offers = [
    { src:  "/Icons and Logos/HomePageMaterials/Landing Images/2019LGC-1600x800-PR.jpg", link: "/#store" ,target: "_self"},
    { src: "/Icons and Logos/HomePageMaterials/Landing Images/16.9-ratio.jpg", target: "_blank", link: "https://bazzaar.com/watches-house/" },
    { src: "/Icons and Logos/HomePageMaterials/Landing Images/casio-mens-outdoor-watch-prt-b50-main.jpg", link: "/#store" },
    // Add more offers as needed
  ];


  const [products, setProducts] = useState([]);


  useEffect(() => {
    fetch('/products.json')
      .then(response => response.json())
      .then(data => setProducts(data));
  }, []);


  const [selectedProducts, setSelectedProducts] = useState([]);
  const selectedRefs = ["T137.407.11.041.00","TW2U85000","TW2V24000","1514083","BJ7006-56L","A 84936 AA04"];  // Example references

  useEffect(() => {
    const filteredProducts = products.filter(product => selectedRefs.includes(product['REF.']));
    setSelectedProducts(filteredProducts);
  }, [products]);

  const settingsNewProducts = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };



  return (
    <div className="home overflow-hidden">

       {/* Cover Image or Video Carousel */}
       <Slider {...settings}>
        {offers.map((offer, index, target) => (
          <div key={index} className="relative  object-cover h-screen w-screen ">
            <img src={offer.src} alt="Special Offer" className="object-cover h-screen md:w-screen" />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-white">
              <a href={offer.link} target={offer.target} className="bg-white text-black py-2 px-4 rounded-full text-lg">Shop Now</a>
            </div>
          </div>
        ))}
      </Slider>

       
      <div className="py-8 mx-12 h-screen slider-container">
      <h2 className="text-3xl  font-bold text-center my-6 mb-16">New Products</h2>
      <Slider {...settingsNewProducts}>
        {selectedProducts.map((product, index) => (
          <ProductCard  key={index} product={product} />
        ))}
      </Slider>
    </div>

     {/* Collections */}
     <div className="flex flex-wrap w-full">
  <div className="w-full md:w-1/2 p-0">
    <a href="https://www.watches-house.com/index.html#/store?gender=Man%2CUnisex" className="block">
      <div className="bg-cover bg-center h-96 rounded-none shadow-lg" style={{ backgroundImage: "url('/Icons and Logos/HomePageMaterials/Collections/Men Images/5db8a2a58a2c946b0dea96fe_continut2.jpg')" }}>
        <div className="flex items-end h-full w-full bg-gradient-to-t from-black to-transparent p-4">
          <h3 className="text-xl text-white">Gents</h3>
        </div>
      </div>
    </a>
  </div>
  <div className="w-full md:w-1/2 p-0">
    <a href="https://www.watches-house.com/index.html#/store?gender=Unisex%2CWoman" className="block">
      <div className="bg-cover bg-center h-96 rounded-none shadow-lg" style={{ backgroundImage: "url('/Icons and Logos/HomePageMaterials/Collections/Women Images/083ace924a5742a6b082095e217061d2.jpg')" }}>
        <div className="flex items-end h-full w-full bg-gradient-to-t from-black to-transparent p-4">
          <h3 className="text-xl text-white">Ladies</h3>
        </div>
      </div>
    </a>
  </div>
</div>

  


    

      {/* Brands Section */}
      <div className="py-8 px-4">
        <h2 className="text-3xl font-bold text-center my-6">Our Brands</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
          {brands.map(renderBrandLogo)}
        </div>
      </div>

      {/* Delivery and Packaging Services */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 py-8 px-4">
        <div className="flex flex-col items-center">
          <h3 className="text-2xl font-bold mb-2">Fast & Reliable Delivery</h3>
          <p>Get your watches delivered fast and with care anywhere in the world.</p>
        </div>
        <div className="flex flex-col items-center">
          <h3 className="text-2xl font-bold mb-2">Premium Packaging</h3>
          <p>Each watch comes with premium packaging, ensuring a high-class unboxing experience.</p>
        </div>
      </div>

    
    </div>
  );
}

export default HomePage;
