import React from "react";

function AboutPage() {
  return (
    <div className="about p-4">
      <div className="hero-image bg-cover bg-center h-64 flex items-center justify-center text-white text-4xl font-bold">
        Welcome to Watches House
      </div>
      <div className="mt-8">
        <h1 className="text-3xl font-bold my-4">About Watches House</h1>
        <p className="my-2">
          Watches House is a premier destination for watch enthusiasts and
          shoppers seeking quality, variety, and exceptional customer service.
          Founded in 1910, our intergenerational mission has been to provide a curated selection
          of watches from renowned and independent brands, catering to diverse
          tastes and budgets. We are commited to servig our community with exceptional cutomer experiance.
        </p>
      </div>
      <div className="my-8">
        <h2 className="text-2xl font-bold my-4">Our Mission</h2>
        <blockquote className="border-l-4 border-blue-500 pl-4">
          "To provide a curated selection of watches that cater to diverse tastes and budgets, offering exceptional customer service."
        </blockquote>
      </div>
      <div className="my-8">
        <h2 className="text-2xl font-bold my-4">Our Story</h2>
        <p className="my-2">
          Our commitment to excellence is evident in every aspect of our business,
          from the careful selection of products to our customer-first service
          approach. We believe in offering more than just a timepiece; we offer a
          personal shopping experience that respects the passion and
          sophistication of our clientele.
        </p>
        <img src="path/to/history-image.jpg" alt="Our History" className="my-4"/>
        <p className="my-2">
          As we continue to grow and evolve, our core values remain the same:
          integrity, quality, and service. We invite you to explore our
          collections and discover the Watches House difference.
        </p>
      </div>
      <div className="my-8">
        <h2 className="text-2xl font-bold my-4">Core Values</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="value-item">
            <img src="path/to/integrity-icon.png" alt="Integrity" className="mx-auto"/>
            <p className="text-center font-bold">Integrity</p>
          </div>
          <div className="value-item">
            <img src="path/to/quality-icon.png" alt="Quality" className="mx-auto"/>
            <p className="text-center font-bold">Quality</p>
          </div>
          <div className="value-item">
            <img src="path/to/service-icon.png" alt="Service" className="mx-auto"/>
            <p className="text-center font-bold">Service</p>
          </div>
        </div>
      </div>
      <div className="my-8 text-center">
        <button className="bg-blue-500 text-white px-4 py-2 rounded">Explore Our Collection</button>
      </div>
    </div>
  );
}

export default AboutPage;
