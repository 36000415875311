// import React, { useState, useEffect } from "react";
// import ProductCard from "../components/ProductCard";
// import Filter from "../components/Filter";

// function StorePage() {
//   const [displayedProducts, setDisplayedProducts] = useState([]);
//   const [products, setProducts] = useState([]);
//   const [filterOptions, setFilterOptions] = useState({
//     priceRanges: [],
//     brands: [],
//     gender: [],
//     origin: [],
//     movement: []
//   });

//   useEffect(() => {
//     fetch("/products.json")
//       .then(response => response.json())
//       .then(data => {
//         setProducts(data);
//         // Initialize an empty set to hold unique movement values
//         const movementOptions = new Set();

//         // Iterate over each product
//         data.forEach((product) => {
//           // Check each info key for 'Movement'
//           for (let i = 1; i <= 40; i++) {
//             // Assuming there are up to 40 info fields
//             if (product[`Info${i}`] === "Movement") {
//               movementOptions.add(product[`Value${i}`]);
//             }
//           }
//         });

        

//         setFilterOptions({
//           priceRanges: ["0 - 50 000 IQD", "50 000 - 100 000 IQD", "100 000 - 200 000 IQD", "200 000 - 400 000 IQD", "400 000 - 800 000 IQD", "+ 800 000 IQD"], // for some reason when I put a comma e.g. 400,000 everything breaks , so I will keep this with a space instead of comma for now , TODO later
//           brands: [...new Set(data.map(product => product.BRAND))].filter(Boolean),
//           gender: [...new Set(data.map(product => product.GENDER))].filter(Boolean),
//           origin: [...new Set(data.map(product => product.ORIGIN))].filter(Boolean),
//           movement: [...movementOptions] // Convert the set to an array          
//         });
//       });
//   }, []);

  
//   const handleFilteredResults = (filteredProducts) => {
//     setDisplayedProducts(filteredProducts);
//   };



//   return (
//     <div className=" min-h-screen z-10">
//       <Filter filterOptions={filterOptions} products={products} onFilterResults={handleFilteredResults}/>
//       <div className="p-4 md:p-16 mt-16 md:mt-20">
//         <h1 className="text-2xl hidden font-bold mb-5">Store</h1>
//         <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
//           {displayedProducts.map((product, index) => (
//             <ProductCard key={index} product={product} />
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }
// export default StorePage;


// import React, { useState, useEffect, useRef, useCallback } from "react";
// import ProductCard from "../components/ProductCard";
// import Filter from "../components/Filter";

// function StorePage() {
//   const [displayedProducts, setDisplayedProducts] = useState([]);
//   const [products, setProducts] = useState([]);
//   const [filterOptions, setFilterOptions] = useState({
//     priceRanges: [],
//     brands: [],
//     gender: [],
//     origin: [],
//     movement: []
//   });
//   const [page, setPage] = useState(1);
//   const observer = useRef();

//   const PRODUCTS_PER_PAGE = 20;

//   useEffect(() => {
//     fetch("/products.json")
//       .then(response => response.json())
//       .then(data => {
//         setProducts(data);
//         // Initialize an empty set to hold unique movement values
//         const movementOptions = new Set();

//         // Iterate over each product
//         data.forEach((product) => {
//           // Check each info key for 'Movement'
//           for (let i = 1; i <= 40; i++) {
//             // Assuming there are up to 40 info fields
//             if (product[`Info${i}`] === "Movement") {
//               movementOptions.add(product[`Value${i}`]);
//             }
//           }
//         });

//         setFilterOptions({
//           priceRanges: ["0 - 50 000 IQD", "50 000 - 100 000 IQD", "100 000 - 200 000 IQD", "200 000 - 400 000 IQD", "400 000 - 800 000 IQD", "+ 800 000 IQD"], // for some reason when I put a comma e.g. 400,000 everything breaks , so I will keep this with a space instead of comma for now , TODO later
//           brands: [...new Set(data.map(product => product.BRAND))].filter(Boolean),
//           gender: [...new Set(data.map(product => product.GENDER))].filter(Boolean),
//           origin: [...new Set(data.map(product => product.ORIGIN))].filter(Boolean),
//           movement: [...movementOptions] // Convert the set to an array          
//         });

//         setDisplayedProducts(data.slice(0, PRODUCTS_PER_PAGE));
//       });
//   }, []);

//   useEffect(() => {
//     if (page === 1) return;
//     const loadMoreProducts = () => {
//       const start = (page - 1) * PRODUCTS_PER_PAGE;
//       const end = start + PRODUCTS_PER_PAGE;
//       setDisplayedProducts(prevProducts => [
//         ...prevProducts,
//         ...products.slice(start, end)
//       ]);
//     };
//     loadMoreProducts();
//   }, [page, products]);

//   const handleFilteredResults = (filteredProducts) => {
//     setDisplayedProducts(filteredProducts.slice(0, PRODUCTS_PER_PAGE));
//     setPage(1);
//     setProducts(filteredProducts);
//   };

//   const lastProductElementRef = useCallback(
//     node => {
//       if (observer.current) observer.current.disconnect();
//       observer.current = new IntersectionObserver(entries => {
//         if (entries[0].isIntersecting) {
//           setPage(prevPage => prevPage + 1);
//         }
//       });
//       if (node) observer.current.observe(node);
//     },
//     []
//   );

//   return (
//     <div className="min-h-screen z-10">
//       <Filter filterOptions={filterOptions} products={products} onFilterResults={handleFilteredResults} />
//       <div className="p-4 md:p-16 mt-16 md:mt-20">
//         <h1 className="text-2xl hidden font-bold mb-5">Store</h1>
//         <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
//           {displayedProducts.map((product, index) => {
//             if (displayedProducts.length === index + 1) {
//               return (
//                 <div ref={lastProductElementRef} key={index}>
//                   <ProductCard product={product} />
//                 </div>
//               );
//             } else {
//               return <ProductCard key={index} product={product} />;
//             }
//           })}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default StorePage;






// import React, { useState, useEffect, useRef, useCallback } from "react";
// import ProductCard from "../components/ProductCard";
// import Filter from "../components/Filter";
// import { mapCharacters } from "../characterMapping"; // Import the mapping function

// function StorePage() {
//   const [displayedProducts, setDisplayedProducts] = useState([]);
//   const [products, setProducts] = useState([]);
//   const [filterOptions, setFilterOptions] = useState({
//     priceRanges: [],
//     brands: [],
//     gender: [],
//     origin: [],
//     movement: []
//   });
//   const [page, setPage] = useState(1);
//   const observer = useRef();

//   const PRODUCTS_PER_PAGE = 20;

//   useEffect(() => {
//     fetch("/products.json")
//       .then(response => response.json())
//       .then(data => {
//         // Map product references
//         const mappedData = data.map(product => ({
//           ...product,
//           mappedRef: mapCharacters(product["REF."])
//         }));

//         setProducts(mappedData);
//         setDisplayedProducts(mappedData.slice(0, PRODUCTS_PER_PAGE));
        
//         const movementOptions = new Set();
//         mappedData.forEach((product) => {
//           for (let i = 1; i <= 40; i++) {
//             if (product[`Info${i}`] === "Movement") {
//               movementOptions.add(product[`Value${i}`]);
//             }
//           }
//         });

//         setFilterOptions({
//           priceRanges: ["0 - 50 000 IQD", "50 000 - 100 000 IQD", "100 000 - 200 000 IQD", "200 000 - 400 000 IQD", "400 000 - 800 000 IQD", "+ 800 000 IQD"],
//           brands: [...new Set(mappedData.map(product => product.BRAND))].filter(Boolean),
//           gender: [...new Set(mappedData.map(product => product.GENDER))].filter(Boolean),
//           origin: [...new Set(mappedData.map(product => product.ORIGIN))].filter(Boolean),
//           movement: [...movementOptions]
//         });
//       });
//   }, []);

//   useEffect(() => {
//     if (page === 1) return;
//     const loadMoreProducts = () => {
//       const start = (page - 1) * PRODUCTS_PER_PAGE;
//       const end = start + PRODUCTS_PER_PAGE;
//       setDisplayedProducts(prevProducts => [
//         ...prevProducts,
//         ...products.slice(start, end)
//       ]);
//     };
//     loadMoreProducts();
//   }, [page, products]);

//   // const handleFilteredResults = (filteredProducts) => {
//   //   console.log("Filtered Products: ", filteredProducts); // Add log to debug
//   //   setDisplayedProducts(filteredProducts.slice(0, PRODUCTS_PER_PAGE));
//   //   setPage(1);
//   //   setProducts(filteredProducts);
//   // };

// const handleFilteredResults = (filteredProducts) => {
//   setDisplayedProducts(filteredProducts);
//   setPage(1);
// };


//   const lastProductElementRef = useCallback(
//     node => {
//       if (observer.current) observer.current.disconnect();
//       observer.current = new IntersectionObserver(entries => {
//         if (entries[0].isIntersecting) {
//           setPage(prevPage => prevPage + 1);
//         }
//       });
//       if (node) observer.current.observe(node);
//     },
//     []
//   );

//   return (
//     <div className="min-h-screen z-10">
//       <Filter filterOptions={filterOptions} products={products} onFilterResults={handleFilteredResults} />
//       <div className="p-4 md:p-16 mt-16 md:mt-20">
//         <h1 className="text-2xl hidden font-bold mb-5">Store</h1>
//         <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
//           {displayedProducts.map((product, index) => {
//             if (displayedProducts.length === index + 1) {
//               return (
//                 <div ref={lastProductElementRef} key={index}>
//                   <ProductCard product={product} />
//                 </div>
//               );
//             } else {
//               return <ProductCard key={index} product={product} />;
//             }
//           })}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default StorePage;



import React, { useState, useEffect, useRef, useCallback } from "react";
import ProductCard from "../components/ProductCard";
import Filter from "../components/Filter";
import { mapCharacters } from "../characterMapping"; // Import the mapping function

function StorePage() {
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    priceRanges: [],
    brands: [],
    gender: [],
    origin: [],
    movement: []
  });
  const [page, setPage] = useState(1);
  const observer = useRef();

  const PRODUCTS_PER_PAGE = 20;

  useEffect(() => {
    fetch("/products.json")
      .then(response => response.json())
      .then(data => {
        // Map product references
        const mappedData = data.map(product => ({
          ...product,
          mappedRef: mapCharacters(product["REF."])
        }));

        setAllProducts(mappedData);
        setFilteredProducts(mappedData);
        setDisplayedProducts(mappedData.slice(0, PRODUCTS_PER_PAGE));
        
        const movementOptions = new Set();
        mappedData.forEach((product) => {
          for (let i = 1; i <= 40; i++) {
            if (product[`Info${i}`] === "Movement") {
              movementOptions.add(product[`Value${i}`]);
            }
          }
        });

        setFilterOptions({
          priceRanges: ["0 - 50 000 IQD", "50 000 - 100 000 IQD", "100 000 - 200 000 IQD", "200 000 - 400 000 IQD", "400 000 - 800 000 IQD", "+ 800 000 IQD"],
          brands: [...new Set(mappedData.map(product => product.BRAND))].filter(Boolean),
          gender: [...new Set(mappedData.map(product => product.GENDER))].filter(Boolean),
          origin: [...new Set(mappedData.map(product => product.ORIGIN))].filter(Boolean),
          movement: [...movementOptions]
        });
      });
  }, []);

  useEffect(() => {
    if (page === 1) return;
    const loadMoreProducts = () => {
      const start = (page - 1) * PRODUCTS_PER_PAGE;
      const end = start + PRODUCTS_PER_PAGE;
      setDisplayedProducts(prevProducts => [
        ...prevProducts,
        ...filteredProducts.slice(start, end)
      ]);
    };
    loadMoreProducts();
  }, [page, filteredProducts]);

  const handleFilteredResults = (newFilteredProducts) => {
    setFilteredProducts(newFilteredProducts);
    setDisplayedProducts(newFilteredProducts.slice(0, PRODUCTS_PER_PAGE));
    setPage(1);
  };

  const lastProductElementRef = useCallback(
    node => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          setPage(prevPage => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    []
  );

  return (
    <div className="min-h-screen z-10">
      <Filter filterOptions={filterOptions} products={allProducts} onFilterResults={handleFilteredResults} />
      <div className="p-4 md:p-16 mt-16 md:mt-20">
        <h1 className="text-2xl hidden font-bold mb-5">Store</h1>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {displayedProducts.map((product, index) => {
            if (displayedProducts.length === index + 1) {
              return (
                <div ref={lastProductElementRef} key={index}>
                  <ProductCard product={product} />
                </div>
              );
            } else {
              return <ProductCard key={index} product={product} />;
            }
          })}
        </div>
      </div>
    </div>
  );
}

export default StorePage;
