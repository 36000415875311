// App.js

import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header'; // Import the Header component
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import StorePage from './pages/StorePage';
import ProductPage from './pages/ProductPage'; 
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';



function App() {
  return (
    <Router>
      <Header /> {/* Use the Header component */}
     
         <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/store" element={<StorePage />} />
        <Route path="/product/:productId" element={<ProductPage />} /> 
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        {/* Define routes for other pages */}

        {/* Fallback route to handle any undefined routes 
        <Route path="*" element={<HomePage />} />*/}
      </Routes>
      <Footer />
     
    </Router>
  );
}

export default App;
