// src/components/ProductCard.js
import React from "react";
import { Link } from "react-router-dom";
import { mapCharacters } from "../characterMapping"; // Import the mapping function

function ProductCard({ product }) {
  // Apply character mapping to REF value
  const mappedRef = mapCharacters(product["REF."]); // Apply character mapping

  // Build the image URL based on the mapped REF value
  const imageUrl = `/ProductsData/Images/MAIN/${mappedRef}_mainPic.jpg`;

  // Apply character mapping to SKU or REF value for the product link
  const productLink = `/product/${mapCharacters(
    product.SKU || product["REF."]
  )}`;

  // Helper function to format price with currency
  const formatPrice = (price) => {
    return price ? `${Number(price).toLocaleString()} IQD` : "No Price";
  };

  return (
    <Link to={productLink} className="block">
      <div className="rounded-lg overflow-hidden z-10 p-3 mx-0 md:mx-4 my-4 bg-white hover:shadow">
        <img
          src={imageUrl}
          alt={product.DESCRIPTION || "Product Image"}
          className="w-full h-72 md:h-58 object-contain"
        />
        <div className="">
          <h2 className="text-lg text-center font-semibold">{product.BRAND}</h2>
          <p className="text-sm text-center">{product["REF."] || "No Ref"}</p>
          <p className="text-sm text-center">
            {product["DISCOUNTED PRICE"] ? (
              <span>
                <span className="text-red-500 text-bold line-through">
                  {formatPrice(product["PRICE"])}
                </span>{" "}
                <span>{formatPrice(product["DISCOUNTED PRICE"])}</span>
              </span>
            ) : (
              <span>{formatPrice(product["PRICE"])}</span>
            )}
          </p>
        </div>
      </div>
    </Link>
  );
}

export default ProductCard;
