import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  // Effect for controlling header visibility on scroll
  useEffect(() => {
    const controlHeaderVisibility = () => {
      if (typeof window !== "undefined") {
        const currentScrollY = window.scrollY;
        setShowHeader(currentScrollY < lastScrollY || currentScrollY === 0);
        setLastScrollY(currentScrollY);
      }
    };

    window.addEventListener("scroll", controlHeaderVisibility);
    return () => window.removeEventListener("scroll", controlHeaderVisibility);
  }, [lastScrollY]);

  // Lock and unlock body scroll based on the mobile menu state
  useEffect(() => {
    if (isMobileOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isMobileOpen]);

  return (
    <header
    

      className={` backdrop-blur-lg backdropf bg-black bg-opacity-70 backdrop-opacity-100 text-white py-6 md:py-5 px-6 md:px-0 fixed  w-full z-40 top-0 left-0 transition-transform duration-300 ease-in-out ${
        showHeader ? "translate-y-0" : "-translate-y-full"
      } rounded-bl-[0px] rounded-br-[0px]`}
    >

      
      <div className="container flex justify-between items-center">
        {/* Mobile Menu Button */}
        <button
          onClick={() => setIsMobileOpen(!isMobileOpen)}
          className="text-white md:hidden z-50"
        >
          <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
            {isMobileOpen ? (
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.3 5.71a1 1 0 011.4 1.42L12.42 14l7.3 7.29a1 1 0 01-1.42 1.4l-7.29-7.3-7.3 7.3a1 1 0 11-1.42-1.42l7.3-7.29-7.3-7.3A1 1 0 113.58 5.7l7.3 7.29 7.29-7.3z"
              ></path>
            ) : (
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 5h16a1 1 0 110 2H4a1 1 0 110-2zm0 6h16a1 1 0 110 2H4a1 1 0 110-2zm0 6h16a1 1 0 110 2H4 a1 1 0 110-2z"
              ></path>
            )}
          </svg>
        </button>
        {/* Logo */}
        <div className="absolute left-1/2 transform -translate-x-1/2 ">
          <Link to="/">
            <img
              src="/Icons and Logos/Logo.png"
              alt="Watches House Logo"
              className="h-12"
            />
          </Link>
        </div>

        {/* <div className={`fixed inset-0  z-40 transform ${isOpen ? "translate-x-0" : "-translate-x-full"} transition-transform duration-300 ease-in-out md:relative md:translate-x-0`}>
          <nav className={`flex flex-col bg-black backdrop-blur-sm bg-opacity-30 z-40 md:flex-row items-center justify-center min-h-screen md:min-h-0 text-2xl lg:text-lg`}>
            <Link to="/" className="px-3 py-3 rounded hover:font-bold" onClick={() => setIsOpen(false)}>Home</Link>
            <Link to="/store" className="px-3 py-3 rounded hover:font-bold" onClick={() => setIsOpen(false)}>Store</Link>
            <Link to="/about" className="px-3 py-3 rounded hover:font-bold" onClick={() => setIsOpen(false)}>About</Link>
            <Link to="/contact" className="px-3 py-3 rounded hover:font-bold" onClick={() => setIsOpen(false)}>Contact</Link>
          </nav>
        </div> */}

        {/* Mobile Menu */}
        {isMobileOpen && (
          <div className="absolute inset-0 bg-white text-black bg-opacity-70 backdrop-blur z-40 flex flex-col items-center justify-center min-h-screen text-2xl transition-transform duration-300 ease-in-out">
            <nav className="flex flex-col items-center justify-center">
              <Link
                to="/"
                className="block px-3 py-3 rounded hover:font-bold"
                onClick={() => setIsMobileOpen(false)}
              >
                Home
              </Link>
              <Link
                to="/store"
                className="block px-3 py-3 rounded hover:font-bold"
                onClick={() => setIsMobileOpen(false)}
              >
                Store
              </Link>
              <Link
                to="/about"
                className="block px-3 py-3 rounded hover:font-bold"
                onClick={() => setIsMobileOpen(false)}
              >
                About
              </Link>
              <Link
                to="/contact"
                className="block px-3 py-3 rounded hover:font-bold"
                onClick={() => setIsMobileOpen(false)}
              >
                Contact
              </Link>
            </nav>
          </div>
        )}
        {/* Desktop Menu */}
        <nav className="hidden md:flex md:mx-14 space-x-8">
          <Link to="/" className=" py-2  rounded  hover:font-bold">
            Home
          </Link>
          <Link to="/store" className=" py-2 rounded hover:font-bold">
            Store
          </Link>
          <Link to="/about" className=" py-2 rounded hover:font-bold">
            About
          </Link>
          <Link to="/contact" className=" py-2 rounded hover:font-bold">
            Contact
          </Link>
        </nav>
      </div>
    </header>
  );
}

export default Header;
