import React from 'react';

function Footer() {
  return (
    <footer className="bg-black backdrop-blur-sm bg-opacity-70 shadow-2xl text-white p-6 mt-10 rounded-tl-[0px] rounded-tr-[0px]">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 text-sm">
        <div className="flex  items-center justify-center md:justify-start md:text-left text-center ">
          <h5 className="font-bold px-3 hidden mb-3">Quick Links</h5>
            <a href="/" className="px-3 hover:font-bold">Home</a>
            <a href="/store" className="px-3 hover:font-bold">Store</a>
            <a href="/about" className="px-3 hover:font-bold">About</a>
            <a href="/contact" className="px-3 hover:font-bold">Contact</a>   
        </div>
        <div className="flex  items-center justify-center md:text-left text-center md:col-span-1">
          <h5 className="font-bold hidden mb-3">Follow Us</h5>
          <div className="flex  items-center justify-center">
          <a href="https://www.facebook.com/watcheshouse1910" className="mx-6 hover:opacity-75 invert">
              <img src="/Icons and Logos/facebook.svg" alt="Facebook" className="h-6 w-6" />
            </a>
            <a href="https://wa.me/+9647511151003?text=Hello,%0ACan%20you%20assist%20me%20to%20place%20an%20order." className="mx-6 hover:opacity-75 invert">
            <img src="/Icons and Logos/whatsapp.svg" alt="WhatsApp" className="h-6 w-6" />
</a>
            <a href="https://www.instagram.com/watcheshouse_official/" className="mx-6 hover:opacity-75 invert">
              <img src="/Icons and Logos/instagram.svg" alt="Instagram" className="h-6 w-6" />
            </a>
            </div>
        </div>
        <div className="md:text-right px-3  text-center">
          <h5 className="font-bold hidden mb-3">Contact Information</h5>
          
          <p className="mb-3 md:mx-6"> <a className="mb-3 md:mx-3">Erbil, Royal Mall, Grd. Floor  </a> <br className='md:hidden'></br> <a href="tel:+9647511151003" className="hover:font-bold"> +(964) 751 1151003</a></p>
          
          <p className="mb-3 md:mx-6">  <a className="mb-3 md:mx-3"> Mosul, Al-Muthana St.</a> <br className='md:hidden'></br> <a href="tel:+9647704121119" className="hover:font-bold">+(964) 770 4121119</a></p>

          <p className="mb-3 md:mx-6"> <a className="mb-3  md:mx-3">Erbil, Family Mall, Grd. Floor  </a> <br className='md:hidden'></br><a href="tel:+9647511151003" className="hover:font-bold"> +(964) 751 1151003</a></p>
        </div>
      </div>
      {/* <div className="container mx-auto grid grid-cols-1 gap-8 text-sm">
      <div className="flex  items-center justify-center">
      <h5 className="font-bold hidden mb-3">Contact Information</h5>
           ©2024 by Watches House
       
      </div>     
      </div> */}

    </footer>
  );
}

export default Footer;
