import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { useParams } from "react-router-dom";

function ProductPage() {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [otherImages, setOtherImages] = useState([]);

  useEffect(() => {
    fetch("/products.json")
      .then((response) => response.json())
      .then((data) => {
        const productDetail = data.find((p) => p.SKU === productId);
        setProduct(productDetail);
        setLoading(false);
        if (productDetail) {
          fetchOtherImages(productDetail["REF."]);
        }
      })
      .catch(() => {
        setProduct(null);
        setLoading(false);
      });
  }, [productId]);

  const fetchOtherImages = (ref) => {
    const maxImages = 10;
    const images = [];

    for (let i = 1; i <= maxImages; i++) {
      const imgSrc = `/ProductsData/Images/OTHER/${ref}_pic${i}.jpg`;
      const img = new Image();
      img.src = imgSrc;

      img.onload = () => {
        images.push(imgSrc);
        setOtherImages([...images]);
        console.log("Image found and added:", imgSrc); // Debugging statement
      };

      img.onerror = () => {
        console.log("Image not found:", imgSrc); // Debugging statement
      };
    }
  };

  if (loading) {
    return (
      <div className="container mx-auto p-4">Loading product details...</div>
    );
  }

  if (!product) {
    return <div className="container mx-auto p-4">Product not found.</div>;
  }

  const handleImageClick = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleBackdropClick = (e) => {
    if (e.target.id === "backdrop") {
      closeModal();
    }
  };

  const formatPrice = (price) => {
    return price ? `${Number(price).toLocaleString()} IQD` : "No Price";
  };

  const mappedRef = product["REF."];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const images = [
    `/ProductsData/Images/MAIN/${mappedRef}_mainPic.jpg`,
    ...otherImages,
  ];

  return (
    <div className="container mx-auto px-4 mt-6 md:mt-24 flex flex-col md:flex-row items-start overflow-hidden">
      <div className="md:w-3/5 w-full p-4 grid grid-cols-1 justify-center items-start">
        <div className="flex justify-center items-start">
          <img
            src={`/ProductsData/Images/MAIN/${mappedRef}_mainPic.jpg`}
            alt={product.DESCRIPTION || "Product Image"}
            className="h-[calc(80vh-40px)] object-contain md:p-10 cursor-pointer"
            onClick={handleImageClick}
          />
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          {otherImages.map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`Additional Image ${index + 1}`}
              className="w-full object-cover"
              onError={(e) => {
                e.target.style.display = "none";
              }} // Hides the image if it fails to load
              onClick={handleImageClick}
            />
          ))}
        </div>
      </div>

      <div className="md:w-2/5 w-full p-4 mt-24">
        <h1 className="text-3xl font-bold mb-4 ">{product.BRAND}</h1>
        <h2 className="text-l mb-3">{product["REF."] || "No REF"}</h2>
        <p className="text-lg text-start mb-4">
          {product["DISCOUNTED PRICE"] ? (
            <span>
              <span className="text-red-500 line-through">
                {formatPrice(product["PRICE"])}
              </span>{" "}
              <span>{formatPrice(product["DISCOUNTED PRICE"])}</span>
            </span>
          ) : (
            <span>{formatPrice(product["PRICE"])}</span>
          )}
        </p>
        {product.DESCRIPTION && (
          <p className="text-lg mb-4">{product.DESCRIPTION}</p>
        )}
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="font-bold">
            <div className="mb-4">Origin </div>
            <div>{product["GENDER"] ? `Gender` : ""}</div>
          </div>
          <div className="text-l">
            <div className="mb-4"> {product.ORIGIN}</div>
            <div> {product["GENDER"] || "No REF"}</div>
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
          {Object.entries(product)
            .filter(([key, value]) => key.startsWith("Info") && value)
            .map(([key, value], index) => (
              <React.Fragment key={index}>
                <div className="font-bold">{value}</div>
                <div>{product[`Value${key.slice(4)}`]}</div>
              </React.Fragment>
            ))}
        </div>
      </div>
      {showModal && (
        <div
          className="fixed inset-0 overflow-hidden z-50"
          id="backdrop"
          onClick={handleBackdropClick}
        >
          <div className="flex justify-center items-center h-screen w-screen">
            <div className="relative p-2 rounded bg-white w-full max-w-4xl mx-auto">
              <Slider {...settings}>
                {images.map((img, index) => (
                  <div key={index} className="flex justify-center items-center">
                    <img
                      src={img}
                      alt={`Product Image ${index + 1}`}
                      className="object-contain h-screen"
                    />
                  </div>
                ))}
              </Slider>
              <button
                className="absolute top-6 right-6 text-2xl p-2 text-black"
                onClick={closeModal}
              >
                &times;
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductPage;
